<template>
  <div>
    <ch-layout page search button>

      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>

      <template #button>
<!--     display: treeSelect.postadd ? 'inline-block' : 'none',    -->
        <ch-button @click="batchReply"  :style="{ marginRight: '10px',}">批量回复</ch-button>
        <ch-button @click="batchClose"  :style="{ marginRight: '10px',}">批量关闭</ch-button>
        <ch-button @click="batchOpen"  :style="{ marginRight: '10px', }">批量启用</ch-button>
        <ch-button @click="batchDelete"  :style="{ marginRight: '10px',}">批量删除</ch-button>
      </template>

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading" @selection-change="tableSelect"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openDetail(scope.row.id)">查看</ch-button>
            <ch-button type="link" v-if="scope.row.replyCount<1 && treeSelect.reply" @click="openReply(scope.row.id)">回复</ch-button>
            <ch-button type="link" v-if="scope.row.status===1 && treeSelect.close" @click="editEvaluate(scope.row.id,0)">启动</ch-button>
            <ch-button type="delete" v-if="scope.row.status===0 && treeSelect.open" @click="editEvaluate(scope.row.id,1)">关闭</ch-button>
            <ch-button type="delete" v-if="treeSelect.delete" @click="handleDelete(scope.row.id)">删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page" :sizeDe="20"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <reply-modal ref="replyModal" @getListFunction="getTableList"></reply-modal>
  </div>
</template>

<script>
import {handleRemoveCheck} from "@/utils/utils";
import replyModal from "@/views/orderManagement/evaluation/replyModal.vue";
import {getTreeSelect} from "@/utils/utils";
export default {
  components: {
    replyModal
  },
  data() {
    return {
      searchForm:{
        goodsName: "",
        orderNo: "",
        staffName: "",
        status: null,
        userName: "",
        userPhone: ""
      },
      tableList: [],
      selectList: [],
      isTableLoading: false,
      star:['一星','二星','三星','四星','五星'],
      treeSelect: {
        batchReply: false,
        batchClose: false,
        batchOpen: false,
        batchDelete: false,
        reply: false,
        close: false,
        open: false,
        delete: false,
      }
    }
  },
  computed: {
    searchOption: function () {
      return [
        {type: 'input', label: '服务订单号：', prop: 'orderNo', placeholder: "请输入订单编号",labelWidth: '120px'},
        {type: 'input', label: '用户姓名：', prop: 'userName', placeholder: "请输入订单编号",},
        {type: 'input', label: '手机号码：', prop: 'userPhone', placeholder: "请输入用户手机号",},
        {type: 'input', label: '服务名称：', prop: 'goodsName', placeholder: "请输入服务名称",},
        {type: 'input', label: '服务人员：', prop: 'staffName', placeholder: "请输入服务名称",},
        {
          type: "select", label: "状态", prop: "status", placeholder: "请选择状态",
          name: "label", value: "value", option: [
            {value: 0, label: "显示"},
            {value: 1, label: "隐藏"},
          ]
        },
        {
          type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]
        },

      ]
    },
    tableOption: function () {
      return [
        {column: "type", prop: "", type: "selection", width: "25px"},
        {column: "type", label: "序号", prop: "", type: "index", width: "70",},
        {column: "text", label: "服务订单编号", prop: "serverNumber", showOverflowTooltip: true,width: "200",},
        {column: "text", label: "用户姓名", prop: "userName", showOverflowTooltip: true,},
        {column: "text", label: "手机号码", prop: "telNumber", showOverflowTooltip: true,},
        {column: "text", label: "服务名称", prop: "goodsName", showOverflowTooltip: true,},
        {column: "text", label: "服务地址", prop: "area", showOverflowTooltip: true,},
        {column: "text", label: "服务人员", prop: "serviceStaffName", showOverflowTooltip: true,},
        {column: "text", label: "用户评价", prop: "evaluation", showOverflowTooltip: true,},
        {column: "text", label: "评价时间", prop: "createTime", showOverflowTooltip: true,width: "160",},
        {column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        {column: "slot", label: "操作", slotName: "handle", width: "180"},
      ];
    },
  },
  created() {
    getTreeSelect(this.$route.name).then(res => {
      this.treeSelect = res
    })
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    // 点击批量选择
    tableSelect(selectOption) {
      this.selectList = selectOption
    },
    // 查询
    searchHandle() {
      this.getTableList()
    },
    getTableList(params=this.searchForm) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/order/evaluateList",params).then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          return {
            ...item,
            _status: item.status === 0 ? '显示' : '隐藏',
            area: item.provinceName + item.cityName + item.countyName + item.detailInfo,
            evaluation: this.star[item.integratedService-1]
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    // 启动 关闭
    editEvaluate(id,status){
      let params = {
        ids:[id],
        status
      }
      this.$curl.post(`/hm/order/evaluateStatus`,params).then((res) => {
        if(res.code === 200){
          this.getTableList()
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 删除
    handleDelete(id) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$curl.put(`/hm/order/evaluateDelete`,{ids:[id]}).then(() => {
          this.getTableList()
          this.$message.success('删除成功')
        })
      })

    },
    openDetail(id){
      this.$router.push({ name: "evaluationDetail", query: { id } });
    },
    openReply(id){
      this.$refs.replyModal.openAdd([id])
    },
    // 批量回复
    batchReply(){
      if(this.selectList.length===0){
        this.$alert('请选择要回复的数据！', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
        return
      }
      let ids = this.selectList.map(item => item.id)
      this.$refs.replyModal.openAdd(ids)
    },
    // 批量关闭
    batchClose(){
      if(this.selectList.length===0){
        this.$alert('请选择要关闭的数据！', '提示', {
          confirmButtonText: '确定',
          type: 'warning',

        })
        return
      }
      let ids = this.selectList.map(item => item.id)
      this.$curl.post(`/hm/order/evaluateStatus`,{ids,status:0}).then((res) => {
        this.getTableList()
        this.$message.success(res.msg)
      })
    },
    // 批量启用
    batchOpen(){
      if(this.selectList.length===0){
        this.$alert('请选择要启用的数据！', '提示', {
          confirmButtonText: '确定',
          type: 'warning',

        })
      }
      let ids = this.selectList.map(item => item.id)
      this.$curl.post(`/hm/order/evaluateStatus`,{ids,status:1}).then((res) => {
        this.getTableList()
        this.$message.success(res.msg)
      })
    },
    batchDelete(){
      if(this.selectList.length===0){
        this.$alert('请选择要删除的数据！', '提示', {
          confirmButtonText: '确定',
          type: 'warning',

        })
        return
      }
      let ids = this.selectList.map(item => item.id)
      handleRemoveCheck('/hm/order/evaluateDelete', {ids}).then(() => {
        this.getTableList()
      })
    },
  }
}

</script>

<style scoped lang="scss">
.btn{
  box-sizing: border-box;
  padding: 7px 15px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  border-radius: 4px;
  color: #3e7eff;
  border: 1px solid #3e7eff;
  background-color: #ecf5ff;
  margin-right: 10px;
}
.active{
  color: #fff;
  background: #3e7eff;
  border: 1px solid #3e7eff;
}
</style>